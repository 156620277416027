// 使用示例
import { request } from "@/utils/request"
// api模块
import chat from "./chat"
import write from "./write"
import common from "./common"
import drawing from "./drawing"
import login from "./login"
import recharge from "./recharge"
import role from "./role"

const apiList = {
	// 公共
	common,
	// 登录
	login,
	// 案例
	case: {
		classify: {
			url: "/addons/chatgpt/archives/cotegory",
			method: "GET",
			desc: "分类"
		},
		list: {
			url: "/addons/chatgpt/archives/index",
			method: "GET",
			desc: "列表"
		},
		detail: {
			url: "/addons/chatgpt/archives/show",
			method: "GET",
			desc: "详情"
		}
	},
	// 用户
	user: {
		info: {
			url: "/addons/chatgpt/user/index",
			method: "GET",
			desc: "用户信息"
		},
		cdkey: {
			url: "/addons/chatgpt/cryptocard/decrypt_card",
			method: "GET",
			desc: "卡密兑换"
		}
	},
	// 会话
	chat,
	// 创作
	write,
	// 角色
	role,
	// 分销
	place: {
		data: {
			url: "/addons/chatgpt/user/userData",
			method: "GET",
			desc: "个人数据"
		},
		team: {
			url: "/addons/chatgpt/reseller/user",
			method: "GET",
			desc: "团队"
		},
		brokerage: {
			url: "/addons/chatgpt/reseller/log",
			method: "GET",
			desc: "分销记录"
		}
	},
	// 充值
	recharge,
	// 绘图
	drawing
}

// 组装接口路径
const getApiPath = path => {
	let apiArray = path.split("."),
		api = apiList
	apiArray.forEach(v => {
		api = api[v]
	})

	return api
}

function http(path, data, error = true) {
	const api = getApiPath(path)
	!api && Promise.reject("接口未定义, 已阻止此次API请求")
	const method = api.method.toLowerCase()
	if (method != "get" && method != "post" && method != "fetch") Promise.reject(`${method} 请求方式未定义, 已阻止此次API请求`)
	return request[method](api.url, data, api.timeout, error)
}

export { apiList, http }
