<!-- 
	This is the default layout, used in sign-in and sign-up pages.
-->

<template>
	<div>
		<a-layout class="layout-default">
			<a-layout-content>
				<keep-alive>
					<router-view v-if="$route.meta.keepAlive" :key="key" />
				</keep-alive>
				<router-view v-if="!$route.meta.keepAlive" :key="key" />
			</a-layout-content>
		</a-layout>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		computed: {
			key() {
				return this.$route.path
			}
		}
	}
</script>

<style lang="scss" scoped>
	.layout-default .ant-layout-content {
		padding-top: 0;
	}
</style>
